import React from "react"

import * as styles from "./styles.module.scss"

const Text = ({ text, name }) => {
  return (
    <div className={styles.karuta_text}>
      <div className={styles.text}>{text}</div>
      <div className={styles.name}>{name}</div>
    </div>
  )
}

export default Text
