import React from "react"

import * as styles from "./styles.module.scss"

const RoundButton = ({ handleClick, children }) => {
  return (
    <button className={styles.round_button} onClick={handleClick}>
      {children}
    </button>
  )
}

export default RoundButton
