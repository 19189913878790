import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import {
  collection,
  query,
  getDocs,
  where,
  documentId,
  doc,
  getDoc,
} from "firebase/firestore"

import { getDB } from "../utils/firebase"

// components
import Seo from "../components/seo"
import BackKaruta from "../components/commons/TopBackgroundKaruta"
import Preview from "../components/commons/Preview"
import CommonHead from "../components/commons/CommonHead"
import RoundButton from "../components/commons/RoundButton"

import HicarIcon from "../images/hicard_icon.svg"

import * as styles from "./styles.module.scss"
import "./style.scss"

let allKarutaList = []
let firstKaruta = null

const IndexPage = () => {
  const [karutaList, setKarutaList] = useState([])
  const [isEmpty, setIsEmpty] = useState(false)
  const [selectedKarutaID, setSelectedKarutaID] = useState(null)
  const db = getDB()

  const limit = 14

  const backScrollStop = fixed => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.body.style.paddingRight = fixed ? `${scrollbarWidth}px` : ""

    const scrollingElement = () => {
      const browser = window.navigator.userAgent.toLowerCase()
      if ("scrollingElement" in document) {
        return document.scrollingElement
      }
      if (browser.indexOf("webkit") > 0) {
        return document.body
      }
      return document.documentElement
    }

    const scrollY = fixed
      ? scrollingElement().scrollTop
      : parseInt(document.body.style.top || "0")

    const styles = {
      height: "100vh",
      left: "0",
      overflow: "hidden",
      position: "fixed",
      top: `${scrollY * -1}px`,
      width: "100vw",
    }

    Object.keys(styles).forEach(key => {
      document.body.style[key] = fixed ? styles[key] : ""
    })

    if (!fixed) window.scrollTo(0, scrollY * -1)
  }

  const getRandamList = () => {
    let counter = 0
    let appendList = []

    const fetchLimit = firstKaruta ? limit - 1 : limit

    if (firstKaruta) {
      appendList.push(firstKaruta)
      firstKaruta = null
    }

    while (counter < fetchLimit) {
      if (allKarutaList.length === 0) {
        break
      }
      const randomIndex = Math.random() * allKarutaList.length
      const popItem = allKarutaList.splice(randomIndex, 1)
      appendList.push(popItem[0])
      counter++
    }

    setKarutaList(karutaList.concat(appendList))
    setIsEmpty(appendList.length < limit)
  }

  const handleModal = id => {
    if (id) {
      backScrollStop(true)
      setSelectedKarutaID(id)
    } else {
      backScrollStop(false)
      setSelectedKarutaID(null)
    }
  }

  const fetchKaruta = id => {
    allKarutaList = []

    if (id) {
      documentFetch(id)
    }

    allDocumentsFetch(id)
    getRandamList()
  }

  const documentFetch = id => {
    const docRef = doc(db, "karuta", id)
    getDoc(docRef).then(docSnapshot => {
      let data = docSnapshot.data()
      data.id = docSnapshot.id
      firstKaruta = data
    })
  }

  const allDocumentsFetch = notInID => {
    const karutaRef = collection(db, "karuta")
    const idCondition = where(documentId(), "!=", notInID)
    const statusCondition = where("status", "==", "show")

    let karutaQuery
    if (notInID) {
      karutaQuery = query(karutaRef, idCondition, statusCondition)
    } else {
      karutaQuery = query(karutaRef, statusCondition)
    }
    getDocs(karutaQuery).then(querySnapshot => {
      let appendList = []
      querySnapshot.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        appendList.push(data)
      })
      allKarutaList = allKarutaList.concat(appendList)
      getRandamList()
    })
  }

  useEffect(() => {
    const karutaID = sessionStorage.getItem("created_id")
    fetchKaruta(karutaID)
    handleModal(karutaID)
    sessionStorage.clear()
  }, [])

  return (
    <>
      <main className={styles.top}>
        <Seo
          title="やりたいことカルタ2022"
          description="今年やりたいことを描いてね"
          meta={[
            {
              property: `og:image`,
              content: `https://firebasestorage.googleapis.com/v0/b/hicard-f4447.appspot.com/o/images%2Fkaruta_ogp%2Ftop.png?alt=media`,
            },
          ]}
        />
        <CommonHead>やりたいことカルタ2022</CommonHead>
        <div className={styles.powered}>
          <div className={styles.text}>powered by</div>
          <a
            className={styles.link}
            href="https://hicard.studio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={HicarIcon} alt="hicard" />
          </a>
        </div>
        <div className={styles.button_container}>
          <RoundButton handleClick={() => navigate("/paint")}>
            描いてみる
          </RoundButton>
        </div>
        <div className={styles.background_karuta_list}>
          {karutaList.map((item, index) => (
            <BackKaruta
              item={item}
              index={index}
              key={index}
              handleClick={id => handleModal(id)}
            />
          ))}
        </div>
        {!isEmpty && (
          <div className={styles.load_button_container}>
            <button onClick={() => getRandamList()}>続きを読み込む</button>
          </div>
        )}
      </main>
      {selectedKarutaID && (
        <Preview
          karutaID={selectedKarutaID}
          handleClose={() => handleModal(null)}
        />
      )}
    </>
  )
}

export default IndexPage
