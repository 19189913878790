import React from "react"

import * as styles from "./styles.module.scss"

const Image = ({ initial, image }) => {
  return (
    <div className={styles.karuta_image}>
      <div className={styles.initial}>{initial}</div>
      <figure className={styles.image_container}>
        <img src={image} alt="かるた画像" />
      </figure>
    </div>
  )
}

export default Image
