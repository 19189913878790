import React, { useEffect, useState } from "react"
import { ref, getDownloadURL } from "firebase/storage"

import { getST } from "../../../utils/firebase"

import * as styles from "./styles.module.scss"

const BackKaruta = ({ item, index, handleClick }) => {
  const [image, setImage] = useState(null)
  const storage = getST()

  const setImageFromID = id => {
    getDownloadURL(ref(storage, `images/karuta/${id}.png`))
      .then(url => {
        setImage(url)
      })
      .catch(error => {
        console.log(error)
        alert("情報の取得に失敗しました")
      })
  }

  useEffect(() => {
    if (item.id) {
      setImageFromID(item.id)
    }
  }, [item.id])

  const rotateList = [5, -9, 13, -7, 11, -5, 9, -13, 7, -11]
  const rotate = rotateList[index % rotateList.length]

  const getZindex = index => {
    const isSP = window.innerWidth <= 768
    if (isSP) {
      return 2 - (index % 2)
    } else {
      return 7 - (index % 7)
    }
  }

  return (
    <div
      className={styles.back_karuta}
      onClick={() => handleClick(item.id)}
      style={{ transform: `rotate(${rotate}deg)`, zIndex: getZindex(index) }}
    >
      <div className={styles.initial}>{item.initial}</div>
      <figure className={styles.image_container}>
        <img src={image} alt="カルタ" />
      </figure>
    </div>
  )
}

export default BackKaruta
