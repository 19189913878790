import React, { useRef } from "react"
import { ref, getDownloadURL } from "firebase/storage"

import { getST } from "../../../../utils/firebase"

import * as styles from "./styles.module.scss"

import TwitterIcon from "../../../../images/preview/twitter_icon.svg"
import FacebookIcon from "../../../../images/preview/facebook_icon.svg"
import LineIcon from "../../../../images/preview/line_icon.svg"
import DownloadIcon from "../../../../images/preview/download_icon.svg"

const Share = ({ karutaID }) => {
  const downloadRef = useRef()

  const storage = getST()

  const shareURL = `https://share.karuta.hicard.studio/${karutaID}`

  const twitterShare = () => {
    window.open(`https://twitter.com/intent/tweet?url=${shareURL}`)
  }

  const facebookShare = () => {
    window.open(`https://www.facebook.com/share.php?u=${shareURL}`)
  }

  const lineShare = () => {
    window.open(`https://line.me/R/share?text=${shareURL}`)
  }

  const downloadShare = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareURL).then(() => {
        getImage()
        // alert("シェアURLをコピーしました")
      })
    } else {
      alert("コピーに失敗しました")
    }
  }

  const downloadImageToLocal = res => {
    let dlLink = document.createElement("a")

    const dataUrl = URL.createObjectURL(res)
    dlLink.href = dataUrl

    const fileName = `karuta.png`
    dlLink.download = fileName

    document.body.insertAdjacentElement("beforeEnd", dlLink)
    dlLink.click()
    dlLink.remove()

    setTimeout(() => {
      window.URL.revokeObjectURL(dataUrl)
    }, 1000)
  }

  const getImage = () => {
    getDownloadURL(ref(storage, `images/karuta/${karutaID}.png`))
      .then(url => {
        var xhr = new XMLHttpRequest()
        xhr.responseType = "blob"
        xhr.onload = () => {
          downloadImageToLocal(xhr.response)
        }
        xhr.open("GET", url, true)
        xhr.send()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className={styles.share_button_container}>
      <button className={styles.share_button} onClick={twitterShare}>
        <img src={TwitterIcon} alt="Twitter" />
      </button>
      <button className={styles.share_button} onClick={facebookShare}>
        <img src={FacebookIcon} alt="Facebook" />
      </button>
      <button className={styles.share_button} onClick={lineShare}>
        <img src={LineIcon} alt="LINE" />
      </button>
      <button className={styles.share_button} onClick={downloadShare}>
        <img src={DownloadIcon} alt="Download" />
      </button>
    </div>
  )
}

export default Share
