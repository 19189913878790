import React, { useEffect, useState } from "react"

import { doc, getDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import { getST, getDB } from "../../../utils/firebase"
import { navigate } from "gatsby-link"

import Image from "./Image"
import Text from "./Text"
import Share from "./Share"

import ReverseIcon from "../../../images/preview/reverse_icon.svg"
import CloseButtonIcon from "../../../images/close_button_icon.svg"

import * as styles from "./styles.module.scss"

const Preview = ({ karutaID, handleClose }) => {
  const [isBack, setIsBack] = useState(false)
  const [initial, setInitial] = useState("")
  const [image, setImage] = useState(null)
  const [text, setText] = useState("")
  const [name, setName] = useState("")

  const db = getDB()
  const storage = getST()

  const getDocument = id => {
    getDoc(doc(db, "karuta", id))
      .then(docSnap => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          setInitial(data.initial)
          setText(data.text)
          setName(data.name)
        } else {
          navigate("/")
        }
      })
      .catch(error => {
        alert("情報の取得に失敗しました")
        navigate("/")
      })
  }

  const getImage = id => {
    getDownloadURL(ref(storage, `images/karuta/${id}.png`))
      .then(url => {
        setImage(url)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (karutaID) {
      getDocument(karutaID)
      getImage(karutaID)
    }
  }, [karutaID])

  return (
    <div className={styles.preview}>
      <div className={styles.layer} onClick={() => handleClose()} />
      <button className={styles.close_button} onClick={() => handleClose()}>
        <img src={CloseButtonIcon} alt="閉じる" />
      </button>
      <div className={styles.container}>
        <div
          className={`${styles.contents_container} ${
            isBack ? styles.fliped : ""
          }`}
        >
          <div className={styles.back} onClick={() => setIsBack(prev => !prev)}>
            <div className={styles.contents}>
              <Text text={text} name={name} />
            </div>
          </div>
          <div
            className={styles.front}
            onClick={() => setIsBack(prev => !prev)}
          >
            <div className={styles.contents}>
              <Image initial={initial} image={image} />
            </div>
          </div>
        </div>
        <div className={styles.reverse_button_container}>
          <button onClick={() => setIsBack(prev => !prev)}>
            <img src={ReverseIcon} alt="裏返す" />
          </button>
        </div>
        <Share karutaID={karutaID} />
      </div>
    </div>
  )
}

export default Preview
