// extracted by mini-css-extract-plugin
export var preview = "styles-module--preview--jEkwq";
export var layer = "styles-module--layer--S7FzA";
export var close_button = "styles-module--close_button--j5iC7";
export var container = "styles-module--container--Vt14G";
export var contents_container = "styles-module--contents_container--JsfJn";
export var front = "styles-module--front--TNzPy";
export var back = "styles-module--back--CPnT+";
export var contents = "styles-module--contents--5dGVS";
export var fliped = "styles-module--fliped--6-0HT";
export var reverse_button_container = "styles-module--reverse_button_container--JecbW";
export var card = "styles-module--card--MGNij";